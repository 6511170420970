import { Locale } from '@harver/journey-shared';
import enGBIcon from '../assets/icons/en-gb.svg';
import enUsIcon from '../assets/icons/en-us.svg';
import nlIcon from '../assets/icons/nl.svg';
import daIcon from '../assets/icons/da.svg';
import deIcon from '../assets/icons/de.svg';
import frIcon from '../assets/icons/fr.svg';
import itIcon from '../assets/icons/it.svg';
import cnIcon from '../assets/icons/cn.svg';
import huIcon from '../assets/icons/hu.svg';
import jpIcon from '../assets/icons/jp.svg';
import koIcon from '../assets/icons/kr.svg';
import noIcon from '../assets/icons/no.svg';
import ptIcon from '../assets/icons/pt.svg';
import ruIcon from '../assets/icons/ru.svg';
import rsIcon from '../assets/icons/rs.svg';
import esIcon from '../assets/icons/es.svg';
import svIcon from '../assets/icons/sv.svg';
import plIcon from '../assets/icons/pl.svg';
import bgIcon from '../assets/icons/bg.svg';
import fiIcon from '../assets/icons/fi.svg';
import beIcon from '../assets/icons/be.svg';
import czIcon from '../assets/icons/cz.svg';
import atIcon from '../assets/icons/at.svg';
import chIcon from '../assets/icons/ch.svg';
import grIcon from '../assets/icons/gr.svg';
import pkIcon from '../assets/icons/pk.svg';
import trIcon from '../assets/icons/tr.svg';
import arXaIcon from '../assets/icons/ar-xa.svg';
import heIlIcon from '../assets/icons/he-il.svg';
import frCaIcon from '../assets/icons/fr-ca.svg';
import latamIcon from '../assets/icons/es-419.svg';
import ptBrIcon from '../assets/icons/pt-br.svg';
import skIcon from '../assets/icons/sk.svg';

type LanguageMetaData = {
    title: string;
    iconPath: string;
};

export const languageMetaDataMapper: Record<Locale, LanguageMetaData> = {
    'en-US': {
        title: 'English (US)',
        iconPath: enUsIcon,
    },
    'nl-NL': {
        title: 'Nederlands',
        iconPath: nlIcon,
    },
    'en-GB': {
        title: 'English (UK)',
        iconPath: enGBIcon,
    },
    'da-DK': {
        title: 'Dansk',
        iconPath: daIcon,
    },
    'fr-FR': {
        title: 'français',
        iconPath: frIcon,
    },
    'it-IT': {
        title: 'Italiano',
        iconPath: itIcon,
    },
    'de-DE': {
        title: 'Deutsch',
        iconPath: deIcon,
    },
    'zh-CN': {
        title: '中文',
        iconPath: cnIcon,
    },
    'hu-HU': {
        title: 'Magyar',
        iconPath: huIcon,
    },
    'ja-JP': {
        title: '日本語',
        iconPath: jpIcon,
    },
    'ko-KR': {
        title: '한국어',
        iconPath: koIcon,
    },
    'nb-NO': {
        title: 'Norsk',
        iconPath: noIcon,
    },
    'pt-PT': {
        title: 'Português',
        iconPath: ptIcon,
    },
    'ru-RU': {
        title: 'Русский',
        iconPath: ruIcon,
    },
    'sr-RS': {
        title: 'Srpski',
        iconPath: rsIcon,
    },
    'es-ES': {
        title: 'Español',
        iconPath: esIcon,
    },
    'sv-SE': {
        title: 'Svenska',
        iconPath: svIcon,
    },
    'pl-PL': {
        title: 'Polskie',
        iconPath: plIcon,
    },
    'bg-BG': {
        title: 'български',
        iconPath: bgIcon,
    },
    'fi-FI': {
        title: 'Suomalainen',
        iconPath: fiIcon,
    },
    'fr-BE': {
        title: 'Français de Belgique',
        iconPath: beIcon,
    },
    'nl-BE': {
        title: 'Vlaams',
        iconPath: beIcon,
    },
    'de-AT': {
        title: 'Deutsch (AT)',
        iconPath: atIcon,
    },
    'cs-CS': {
        title: 'čeština',
        iconPath: czIcon,
    },
    'de-CH': {
        title: 'Deutsch (CH)',
        iconPath: chIcon,
    },
    'it-CH': {
        title: 'Italiano (CH)',
        iconPath: chIcon,
    },
    'el-GR': {
        title: 'Ελληνικά',
        iconPath: grIcon,
    },
    'ur-UR': {
        title: 'اردو',
        iconPath: pkIcon,
    },
    'pt-BR': {
        title: 'Português (BR)',
        iconPath: ptBrIcon,
    },
    'es-419': {
        title: 'Español (LATAM)',
        iconPath: latamIcon,
    },
    'sk-SK': {
        title: 'slovenský',
        iconPath: skIcon,
    },
    'fr-CA': {
        title: 'Français (Canada)',
        iconPath: frCaIcon,
    },
    'tr-TR': {
        title: 'Turkish',
        iconPath: trIcon,
    },
    'ar-XA': {
        title: 'عربي',
        iconPath: arXaIcon,
    },
    'he-IL': {
        title: 'עִברִית',
        iconPath: heIlIcon,
    },
};

export const getLanguageTitle = (language: Locale): string => {
    return languageMetaDataMapper[language].title;
};

export const getLanguageIcon = (language: Locale): string => {
    return languageMetaDataMapper[language].iconPath;
};

export const mapLegacyLocale = (oldCJLocale: string): Locale => {
    switch (oldCJLocale) {
        case 'ENG':
            return Locale['en-US'];
        case 'NL':
            return Locale['nl-NL'];
        case 'FR':
            return Locale['fr-FR'];
        case 'DE':
            return Locale['de-DE'];
        case 'ES':
            return Locale['es-ES'];
        case 'IT':
            return Locale['it-IT'];

        default: {
            if (!oldCJLocale) {
                return Locale['en-US']; // TODO: This should be default language of a flow
            }
            return oldCJLocale.replace('_', '-') as Locale; // * NOTE: Until otherwise, future locales will map appropriately like this.
        }
    }
};
