import React, { FC, lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { FlowConfigProvider } from './context/FlowConfigContext';
import { UserProvider } from './context/UserContext';
import ErrorBoundary from './pages/components/ErrorBoundary';
import { LoadingState, SharedUiThemeProvider } from '@harver/shared-ui';
import { GlobalStyle } from './theme/GlobalStyle';
import { getAPIbaseURL } from './utils/httpClient';
import { LocalizationProvider } from './context/LocalizationContext';
import { HelmetProvider } from 'react-helmet-async';

// First implementation, using fake empty profile page as a loader
// Super simple, theme and other components are not loaded so it
// will not make main bundle much bigger. In the future should support
// branding, or be replaced with something else.
import { LoadingSpinner } from './pages/components/LoadingSpinner';
import { VacancyTitleProvider } from './context/VacancyContext';
import { ChatbotProvider } from './context/ChatbotContext';

const isDeployable = process.env.REACT_APP_DEPLOYMENT_ENV !== 'local';
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const ErrorPage = lazy(() => import('./pages/ErrorPage'));
const FlowPage = lazy(() => import('./pages/FlowPage'));
const VacancyPage = lazy(() => import('./pages/vacancy'));
const DevelopmentPage = lazy(() => import('./pages/DevelopmentPage'));
const whereToAuth = new URL('/api/auth', getAPIbaseURL());

const ExternalRedirect = ({ to }) => {
    window.location = to.href;
    return null;
};

const LoadingFallback = () => (
    <SharedUiThemeProvider>
        <LoadingState />
    </SharedUiThemeProvider>
);

// TODO:
// Some providers will need to (later on) be moved to the root level,
// as they will be used by other routes, for example: Language.
// We will need to provide a default value for it, as well as one for the "theme",
// so routes that don't have a `flowConfig` can still render correclty,
// and make use of a "default theme" and the system translations.
const CoreApp: FC = () => {
    return (
        <HelmetProvider>
            <ChatbotProvider>
                <BrowserRouter>
                    <ErrorBoundary
                        renderErrorPage={(_error) => (
                            <Suspense fallback={<LoadingSpinner testId="errorPage.loadingSpinner" />}>
                                <SharedUiThemeProvider>
                                    <GlobalStyle />
                                    <ErrorPage />
                                </SharedUiThemeProvider>
                            </Suspense>
                        )}
                    >
                        <Switch>
                            <Route path="/auth">
                                <ExternalRedirect to={whereToAuth} />
                            </Route>
                            <Route path="/flow/:flowId">
                                <ErrorBoundary
                                    renderErrorPage={(_error) => (
                                        <Suspense fallback={<LoadingSpinner testId="errorPage.loadingSpinner" />}>
                                            <SharedUiThemeProvider>
                                                <GlobalStyle />
                                                <ErrorPage isLegacyJourney={true} />
                                            </SharedUiThemeProvider>
                                        </Suspense>
                                    )}
                                >
                                    <Suspense fallback={<LoadingSpinner testId="flowPage.loadingSpinner" />}>
                                        <FlowConfigProvider>
                                            <UserProvider>
                                                <LocalizationProvider>
                                                    <VacancyTitleProvider initialValue="">
                                                        <FlowPage />
                                                    </VacancyTitleProvider>
                                                </LocalizationProvider>
                                            </UserProvider>
                                        </FlowConfigProvider>
                                    </Suspense>
                                </ErrorBoundary>
                            </Route>
                            <Route path={['/vacancy', '/apply']}>
                                <Suspense fallback={<LoadingFallback />}>
                                    <LocalizationProvider>
                                        <VacancyTitleProvider>
                                            <VacancyPage />
                                        </VacancyTitleProvider>
                                    </LocalizationProvider>
                                </Suspense>
                            </Route>
                            <Route path="/preload">
                                {/* NOTE: An empty route is needed to preload assets from the Old CJ. */}
                            </Route>
                            <Route>
                                <Suspense fallback={<LoadingFallback />}>
                                    <SharedUiThemeProvider>
                                        <GlobalStyle />
                                        <NotFoundPage />
                                    </SharedUiThemeProvider>
                                </Suspense>
                            </Route>
                        </Switch>
                    </ErrorBoundary>
                </BrowserRouter>
            </ChatbotProvider>
        </HelmetProvider>
    );
};

const DevApp = () => (
    <BrowserRouter>
        <Switch>
            <Route path="/" exact>
                <Suspense fallback={<LoadingSpinner />}>
                    <DevelopmentPage />
                </Suspense>
            </Route>
            <Route component={CoreApp} />
        </Switch>
    </BrowserRouter>
);

const App = isDeployable ? CoreApp : DevApp;

export default App;
