"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Locale = exports.RTLLocales = exports.isLegacyLocaleOrISOLocale = exports.isLegacyLocale = exports.isISOLocale = void 0;
const shared_types_1 = require("@harver/shared-types");
Object.defineProperty(exports, "Locale", { enumerable: true, get: function () { return shared_types_1.Locale; } });
const isISOLocale = (locale) => {
    return Boolean(locale && Object.values(shared_types_1.Locale).includes(String(locale)));
};
exports.isISOLocale = isISOLocale;
const isLegacyLocale = (locale) => {
    return Boolean(locale && ['ENG', 'NL', 'FR', 'DE', 'ES', 'IT'].includes(String(locale)));
};
exports.isLegacyLocale = isLegacyLocale;
const isLegacyLocaleOrISOLocale = (locale) => {
    return (0, exports.isISOLocale)(locale) || (0, exports.isLegacyLocale)(locale);
};
exports.isLegacyLocaleOrISOLocale = isLegacyLocaleOrISOLocale;
exports.RTLLocales = shared_types_1.Shared.RTLLocales;
