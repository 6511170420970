import useApiFetch from './useApiFetch';

const useFeatureToggle = (name: string, context?: Record<string, string>) => {
    const searchParams = context ? `?${new URLSearchParams(context).toString()}` : '';
    const [data, loading, error] = useApiFetch<boolean>({
        path: `/feature/isEnabled/${name}${searchParams}`,
    });

    return [data, loading, error] as const;
};

export default useFeatureToggle;
