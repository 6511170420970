import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// * Taken from react-router-dom docs
// * link: https://v5.reactrouter.com/web/example/query-parameters
const useQuery = () => {
    const { search } = useLocation();
    const history = useHistory();

    const appendQueryParams = (params: Record<string, string>) => {
        const queryParams = new URLSearchParams(search);

        Object.entries(params).forEach(([key, value]) => {
            queryParams.set(key, value);
        });

        history.replace({
            search: queryParams.toString(),
        });
    };

    const deleteQueryParams = (...keys: string[]) => {
        const params = new URLSearchParams(search);

        keys.forEach((key) => {
            params.delete(key);
        });

        history.replace({
            search: params.toString(),
        });
    };

    return [useMemo(() => new URLSearchParams(search), [search]), appendQueryParams, deleteQueryParams] as const;
};

export default useQuery;
