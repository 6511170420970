import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { Locale, isLegacyLocaleOrISOLocale } from '@harver/journey-shared';
import { ReactIntl, useLocalStorage } from '@harver/shared-ui';
import useQuery from '../hooks/useQuery';
import { mapLegacyLocale } from '../data/languages';

type LocalizationProviderProps = {
    children: ReactNode;
    initialMessages?: Record<string, string>;
    initialLanguage?: Locale;
};

type SetMessagesDispatch = (newMessages: Record<string, string>) => void;
type SetLanguageDispatch = (newLanguage: Locale) => void;
type SetLanguagesDispatch = (newLanguages: Locale[]) => void;

const LocalizationSetMessagesContext = createContext<SetMessagesDispatch | undefined>(undefined);
const LanguageStateContext = createContext<Locale | undefined>(undefined);
const LanguagesStateContext = createContext<Locale[] | undefined>(undefined);
const LanguageSetStateContext = createContext<SetLanguageDispatch | undefined>(undefined);
const LanguagesSetStateContext = createContext<SetLanguagesDispatch | undefined>(undefined);

export const LocalizationProvider: React.FC<LocalizationProviderProps> = ({
    children,
    initialMessages,
    initialLanguage,
}: LocalizationProviderProps) => {
    const [query, , deleteQueryParams] = useQuery();
    const queryPreferredVacancyLanguage = query.get('language');
    const [userPreferredLanguage, setUserPreferredLanguage] = useLocalStorage(`cj_user_preferred_language`, null);

    // Default language precendence: queryPreferredVacancyLanguage > userPreferredLanguage > initialLanguage > en-US
    // since the localstorage and query hooks are async they probably not have a value upon initial rendering of the page
    // we have to use an useEffect hook to set the default language when that data is there (see below)
    const defaultLanguage = initialLanguage ?? Locale['en-US'];

    const [messages, setMessages] = useState(initialMessages);
    const [language, setLanguage] = useState<Locale>(defaultLanguage);
    const [flowLanguages, setFlowLanguages] = useState<Locale[]>([]);

    const updateMessages = useCallback(
        (addedMessages) => {
            setMessages((prevMessages) => ({
                ...prevMessages,
                ...addedMessages,
            }));
        },
        [setMessages],
    );

    const updateLanguage = useCallback(
        (newLanguage: Locale) => {
            setLanguage(newLanguage);
            setUserPreferredLanguage(newLanguage);
        },
        [setUserPreferredLanguage],
    );

    useEffect(() => {
        if (isLegacyLocaleOrISOLocale(queryPreferredVacancyLanguage)) {
            updateLanguage(mapLegacyLocale(queryPreferredVacancyLanguage));
            deleteQueryParams('language');

            return;
        }

        if (isLegacyLocaleOrISOLocale(userPreferredLanguage) && language !== userPreferredLanguage) {
            updateLanguage(mapLegacyLocale(userPreferredLanguage));
        }
    }, [
        userPreferredLanguage,
        setLanguage,
        language,
        queryPreferredVacancyLanguage,
        updateLanguage,
        deleteQueryParams,
    ]);

    return (
        <LanguageStateContext.Provider value={language}>
            <LanguageSetStateContext.Provider value={updateLanguage}>
                <LanguagesStateContext.Provider value={flowLanguages}>
                    <LanguagesSetStateContext.Provider value={setFlowLanguages}>
                        <LocalizationSetMessagesContext.Provider value={updateMessages}>
                            <ReactIntl.IntlProvider locale={language} messages={messages}>
                                {children}
                            </ReactIntl.IntlProvider>
                        </LocalizationSetMessagesContext.Provider>
                    </LanguagesSetStateContext.Provider>
                </LanguagesStateContext.Provider>
            </LanguageSetStateContext.Provider>
        </LanguageStateContext.Provider>
    );
};

export const useLocalizationSetMessages = () => {
    return useContext(LocalizationSetMessagesContext);
};

export const useLanguageState = () => {
    return useContext(LanguageStateContext);
};

export const useLanguageSetState = () => {
    return useContext(LanguageSetStateContext);
};

export const useLanguagesState = () => {
    return useContext(LanguagesStateContext);
};

export const useLanguagesSetState = () => {
    return useContext(LanguagesSetStateContext);
};
