import useFetch, { UseFetchParams } from './useFetch';
import { getAPIbaseURL, handleApiRequestError } from '../utils/httpClient';
import { useCallback, useEffect, useMemo } from 'react';
import { useSetErrorDetails } from '../context/ErrorContext';

const createOptions = (options: RequestInit, payload?: object): RequestInit => {
    return {
        ...options,
        credentials: 'include', // * NOTE: Include cookies in cross-origin requests (example our API)
        headers: {
            ...options?.headers,
            'Content-Type': 'application/json', // * NOTE: Send the request body as JSON
        },
        ...(payload && { body: JSON.stringify(payload) }),
    };
};

export interface FetchParams {
    options?: UseFetchParams['defaultOptions'];
    payload?: object;
}

type UseApiFetchParams = {
    path: string;
    method?: 'GET' | 'POST';
    payload?: object;
    mount?: boolean;
};

export interface HttpError extends Error {
    payload?: {
        httpStatusCode: number;
        message?: string;
        errorCode?: string;
    };
}

const useApiFetch = <Data = unknown>({ path, method = 'GET', payload, mount = true }: UseApiFetchParams) => {
    const setErrorDetails = useSetErrorDetails();
    const defaultOptions = useMemo(() => {
        return { method };
    }, [method]);

    const optionsForFetch = useMemo(() => createOptions(defaultOptions, payload), [defaultOptions, payload]);

    const [data, isLoading, error, _doFetch, resetState] = useFetch<Data>({
        url: `${getAPIbaseURL()}${path.toString()}`,
        defaultOptions: optionsForFetch,
        mount,
    });

    useEffect(() => {
        if (error) {
            try {
                // * NOTE: `handleApiRequestError` re-throws the error and we don't have a way of catching it from outside the hook.
                handleApiRequestError(error, path, `Error while sending a ${defaultOptions.method} request`);
            } catch (err) {
                //
            }
        }

        if (error?.payload) {
            const {
                errorReportId: errorId,
                message: code,
                httpStatusCode,
                errorCode,
                ...restOfErrorPayload
            } = error.payload;

            setErrorDetails({ errorId, code, errorCode, httpStatusCode, ...restOfErrorPayload });
        }
    }, [error, defaultOptions.method, setErrorDetails, path]);

    const doFetch = useCallback(
        async ({ options = defaultOptions, payload: _payload }: FetchParams = {}) => {
            const newOptions = createOptions(options, _payload);
            return _doFetch(newOptions);
        },
        [_doFetch, defaultOptions],
    );

    return [data, isLoading, error, doFetch, resetState] as const;
};

export default useApiFetch;
